@import "swiper/swiper-bundle.css";

.swiper-horizontal > .swiper-scrollbar {
  @apply h-[1px];
  @apply bg-gray-200;
  @apply bottom-6 left-8 right-44 lg:left-1/2 lg:-translate-x-1/2;
  @apply w-auto lg:w-full lg:max-w-6xl;
  @apply lg:mx-auto;

  .swiper-scrollbar-drag {
    @apply bg-gray-700;
  }
}

.swiper-button-prev,
.swiper-button-next {
  @apply absolute top-1/2;
  @apply transition-opacity;
  @apply bg-white hover:bg-white/75;
  @apply h-12 w-12 rounded-full;
  @apply transition-colors;
  @apply shadow-md;
  @apply bg-no-repeat;
  @apply bg-center;
  @apply after:hidden;
  @apply z-40;
}

.swiper-button-next {
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='18' height='18' viewBox='0 0 24 24' fill='none' stroke='currentColor' stroke-width='1.5' stroke-linecap='round' stroke-linejoin='round'%3E%3Cpath d='M9 18l6-6-6-6'/%3E%3C/svg%3E");

  @apply right-2 md:right-6 lg:right-8;
  @apply active:translate-x-[1px];
}

.swiper-button-prev {
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='18' height='18' viewBox='0 0 24 24' fill='none' stroke='currentColor' stroke-width='1.5' stroke-linecap='round' stroke-linejoin='round'%3E%3Cpath d='M15 18l-6-6 6-6'/%3E%3C/svg%3E");

  @apply left-2 md:left-6 lg:left-8;
  @apply active:-translate-x-[1px];
}

.swiper-button-disabled {
  @apply opacity-50;
  @apply pointer-events-none;
}

.swiper-thumbs {
  .swiper-button-prev,
  .swiper-button-next {
    @apply top-1/3;
  }
}
