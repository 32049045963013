@layer utilities {
  .smooth-scroll {
    scroll-behavior: smooth;
  }

  .text-size-adjust {
    -webkit-text-size-adjust: 100%;
  }

  .optimize-legibility {
    text-rendering: optimizeLegibility;
  }

  .no-scrollbar::-webkit-scrollbar {
    display: none; /* Chrome, Safari and Opera */
  }

  .no-scrollbar {
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
  }

  .hover-scale {
    @apply transition-transform duration-300 group-hover:scale-[1.0125];
  }

  .fade-in {
    @apply transition-opacity duration-500 ease-out;
  }

  .bg-split-r-gray-50 {
    background: linear-gradient(90deg, rgba(255, 255, 255, 0) 50%, #fafafa 50%);
  }

  .small-caps {
    @apply uppercase tracking-tight;
  }

  .heading-4xl {
    @apply font-serif;
    @apply text-8xl md:text-9xl lg:text-9xl xl:text-10xl 2xl:text-10xl;
    @apply leading-tightest lg:tracking-tight;
  }

  .heading-3xl {
    @apply font-serif;
    @apply text-6xl md:text-7xl lg:text-8xl xl:text-9xl 2xl:text-9xl;
    @apply leading-tightest lg:tracking-tight;
  }

  .heading-2xl {
    @apply font-serif;
    @apply text-5xl md:text-6xl lg:text-7xl xl:text-7xl 2xl:text-8xl;
    @apply leading-tightest lg:tracking-tight;
  }

  .heading-xl {
    @apply font-serif;
    @apply text-4xl md:text-5xl lg:text-6xl 2xl:text-7xl;
    @apply leading-tightest lg:tracking-tight;
  }

  .heading-lg {
    @apply font-serif;
    @apply text-3xl md:text-4xl 2xl:text-5xl;
  }

  .heading-md {
    @apply font-serif;
    @apply text-2xl md:text-3xl lg:text-4xl;
  }

  .heading-sm {
    @apply font-serif;
    @apply text-xl lg:text-2xl xl:text-3xl;
  }

  .heading-xs {
    @apply font-semibold;
    @apply text-base lg:text-lg xl:text-xl;
  }

  .hidden-scroll-bar {
    &::-webkit-scrollbar-track {
      @apply invisible;
    }

    &::-webkit-scrollbar {
      @apply invisible;
    }

    &::-webkit-scrollbar-thumb {
      @apply invisible;
    }
  }

  .hover-ball {
    @apply absolute bottom-0 block h-[7px] w-[7px] translate-y-5 rounded-full bg-red opacity-0 transition-all ease-in-out;
  }

  .hover-ball--active {
    @apply translate-y-3 opacity-100;
  }

  .shadow-max {
    box-shadow:
      0px 13px 80px rgba(0, 0, 0, 0.05),
      0px 8.42593px 46.8519px rgba(0, 0, 0, 0.037963),
      0px 5.00741px 25.4815px rgba(0, 0, 0, 0.0303704),
      0px 2.6px 13px rgba(0, 0, 0, 0.025),
      0px 1.05926px 6.51852px rgba(0, 0, 0, 0.0196296),
      0px 0.240741px 3.14815px rgba(0, 0, 0, 0.012037);
  }

  .disable-translate-3d {
    transform: translate3d(0, 0, 0) !important;
  }

  .ease-in-back {
    transition-timing-function: cubic-bezier(0.6, -0.28, 0.735, 0.045);
  }

  .ease-in-circ {
    transition-timing-function: cubic-bezier(0.6, 0.04, 0.98, 0.335);
  }

  .ease-in-cubic {
    transition-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
  }

  .ease-in-expo {
    transition-timing-function: cubic-bezier(0.95, 0.05, 0.795, 0.035);
  }

  .ease-in-out-back {
    transition-timing-function: cubic-bezier(0.68, -0.55, 0.265, 1.55);
  }

  .ease-in-out-circ {
    transition-timing-function: cubic-bezier(0.785, 0.135, 0.15, 0.86);
  }

  .ease-in-out-cubic {
    transition-timing-function: cubic-bezier(0.645, 0.045, 0.355, 1);
  }

  .ease-in-out-expo {
    transition-timing-function: cubic-bezier(1, 0, 0, 1);
  }

  .ease-in-out-quad {
    transition-timing-function: cubic-bezier(0.455, 0.03, 0.515, 0.955);
  }

  .ease-in-out-quart {
    transition-timing-function: cubic-bezier(0.77, 0, 0.175, 1);
  }

  .ease-in-out-quint {
    transition-timing-function: cubic-bezier(0.86, 0, 0.07, 1);
  }

  .ease-in-out-sine {
    transition-timing-function: cubic-bezier(0.445, 0.05, 0.55, 0.95);
  }

  .ease-in-quad {
    transition-timing-function: cubic-bezier(0.55, 0.085, 0.68, 0.53);
  }

  .ease-in-quart {
    transition-timing-function: cubic-bezier(0.895, 0.03, 0.685, 0.22);
  }

  .ease-in-quint {
    transition-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
  }

  .ease-in-sine {
    transition-timing-function: cubic-bezier(0.47, 0, 0.745, 0.715);
  }

  .ease-out-back {
    transition-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1.275);
  }

  .ease-out-circ {
    transition-timing-function: cubic-bezier(0.075, 0.82, 0.165, 1);
  }

  .ease-out-cubic {
    transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
  }

  .ease-out-expo {
    transition-timing-function: cubic-bezier(0.19, 1, 0.22, 1);
  }

  .ease-out-quad {
    transition-timing-function: cubic-bezier(0.25, 0.46, 0.45, 0.94);
  }

  .ease-out-quart {
    transition-timing-function: cubic-bezier(0.165, 0.84, 0.44, 1);
  }

  .ease-out-quint {
    transition-timing-function: cubic-bezier(0.23, 1, 0.32, 1);
  }

  .ease-out-sine {
    transition-timing-function: cubic-bezier(0.39, 0.575, 0.565, 1);
  }

  .pt-header {
    @apply pt-36 md:pt-40 lg:pt-80;
  }

  .hanging-punctuation {
    text-indent: -0.275em;

    @​supports (hanging-punctuation: first) {
      hanging-punctuation: first;
      text-indent: 0;
    }
  }
}
